import APIService from "./APIService";
import axios from "axios";
import { getInstance } from "../auth/auth";
import { addMinutes, isPast, parseISO } from "date-fns";

const apiHttp = APIService.http;
const http = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_BUSINESS_CHATTER_URL,
});

export default {
  business_chatter_url: process.env.VUE_APP_BUSINESS_CHATTER_URL,
  async authenticate(company) {
    let key_key = `${company.companyId}-repmgmt-key`;
    let local_key = window.localStorage.getItem(key_key);
    if (local_key) {
      let key_obj = JSON.parse(local_key);
      if (!isPast(parseISO(key_obj.expires))) {
        return key_obj.key;
      }
    }

    let key = this.getAPIKey(company);
    return key;
  },
  async addCompany(company) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await apiHttp.post(
      `api/ReputationManagement/setup`,
      company,
      authHeaders
    );
    return res;
  },
  async getAPIKey(company) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await apiHttp.post(
      "api/ReputationManagement/hasAccess",
      company,
      authHeaders
    );
    let key = res.data;
    if (key !== "" && key !== null) {
      let key_key = `${company.companyId}-repmgmt-key`;
      window.localStorage.setItem(
        key_key,
        JSON.stringify({
          key: key,
          expires: addMinutes(new Date(), 15),
        })
      );
    }
    return key;
  },
  async apiAuthConfig(company) {
    let key = await this.authenticate(company);
    if (key) {
      return {
        headers: {
          Authorization: `Secret ${key}`,
        },
      };
    } else {
      return {};
    }
  },
  async hasAccess(company) {
    let key = await this.getAPIKey(company);
    return key !== "" && key !== null;
  },
  async autoSetup(company, setup) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.post("review/company/auto-setup/", setup, authHeaders);
    return res.data;
  },
  async refreshAPIKey(company) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.post(
      "review/company/refresh-api-key/",
      null,
      authHeaders
    );
    return res.data;
  },
  async getCompany(company) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.get("review/company/", authHeaders);
    return res.data;
  },
  async getRecentReviews(company) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.get("review/company/recent-reviews/", authHeaders);
    return res.data;
  },
  async getWaitingReviews(company) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.get("review/company/waiting-reviews/", authHeaders);
    return res.data;
  },
  async getMembers(company) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.get("review/company/members/", authHeaders);
    return res.data;
  },
  async getCompanySerpScan(company) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.get("serp/latest-result/", authHeaders);
    return res.data;
  },
  async getReviewFlows(company) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.get("review/companyreviewflows/", authHeaders);
    return res.data;
  },
  async getCohort(company, cohortId) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.get(`cohorts/cohorts/${cohortId}/`, authHeaders);
    return res.data;
  },
  async updateCohort(company, cohort) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.put(
      `cohorts/cohorts/${cohort.id}/`,
      cohort,
      authHeaders
    );
    return res.data;
  },
  async addCohort(company, cohort) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.post(`cohorts/cohorts/`, cohort, authHeaders);
    return res.data;
  },
  async getCohortMembers(company, cohortId) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.get(
      `cohorts/cohorts/${cohortId}/members/`,
      authHeaders
    );
    return res.data;
  },
  async updateCohortMember(company, cohortId, member) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.put(
      `cohorts/cohorts/${cohortId}/members/${member.id}/`,
      member,
      authHeaders
    );
    return res.data;
  },
  async deleteCohortMember(company, cohortId, member) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.delete(
      `cohorts/cohorts/${cohortId}/members/${member.id}/`,
      authHeaders
    );
    return res.data;
  },
  async addCohortMember(company, cohortId, member) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.post(
      `cohorts/cohorts/${cohortId}/members/`,
      member,
      authHeaders
    );
    return res.data;
  },
  async getReviewFlowCategories(company) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.get(`review/reviewflowcategories/`, authHeaders);
    return res.data;
  },
  async getReviewFlowCategoryFlows(company, category) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.get(
      `review/reviewflowcategories/${category}/review-flows/`,
      authHeaders
    );
    return res.data;
  },
  async addReviewFlow(company, reviewFlow) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.post(
      `review/companyreviewflows/`,
      reviewFlow,
      authHeaders
    );
    return res.data;
  },
  async getReviewFlow(company, reviewFlow) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.get(
      `review/companyreviewflows/${reviewFlow}/`,
      authHeaders
    );
    return res.data;
  },
  async getReviewFlowCounts(company, reviewFlow) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.get(
      `review/companyreviewflows/${reviewFlow}/review_counts/`,
      authHeaders
    );
    return res.data;
  },
  async getReviewFlowReviews(company, reviewFlow) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.get(
      `review/companyreviewflows/${reviewFlow}/reviews/`,
      authHeaders
    );
    return res.data;
  },
  async getReviewFlowCohorts(company, reviewFlow) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.get(
      `review/companyreviewflows/${reviewFlow}/cohorts/`,
      authHeaders
    );
    return res.data;
  },
  async getTemplates(company) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.get(`cohorts/templates/`, authHeaders);
    return res.data;
  },
  async getTemplate(company, template) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.get(
      `cohorts/templates/${template}/private_key/`,
      authHeaders
    );
    return res.data;
  },
  async getUnsavedTemplatePreview(company, template, cohortMember) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.post(
      `cohorts/templates/preview_unsaved/${cohortMember}/`,
      template,
      authHeaders
    );
    return res.data;
  },
  async getTemplatePreview(company, template, cohortMember) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.get(
      `cohorts/templates/${template}/preview/${cohortMember}/`,
      authHeaders
    );
    return res.data;
  },
  async updateTemplate(company, template) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.put(
      `cohorts/templates/${template.id}/`,
      template,
      authHeaders
    );
    return res.data;
  },
  async saveTemplate(company, template) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.post(`cohorts/templates/`, template, authHeaders);
    return res.data;
  },
  async getWorkflows(company) {
    return (await this.getAllWorkflows(company)).filter(
      (w) => w.superseded_by.length == 0
    );
  },
  async getAllWorkflows(company) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.get(`cohorts/machines/`, authHeaders);
    return res.data;
  },
  async getWorkflow(company, workflow) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.get(`cohorts/machines/${workflow}/`, authHeaders);
    return res.data;
  },
  async saveWorkflow(company, workflow) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.post(`cohorts/machines/`, workflow, authHeaders);
    return res.data;
  },
  async getQueries(company) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.get(`serp/queries/`, authHeaders);
    return res.data;
  },
  async addQuery(company, query) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.post(`serp/add-query/`, query, authHeaders);
    return res.data;
  },
  async refreshQuery(company, query) {
    let authHeaders = await this.apiAuthConfig(company);
    let res = await http.post(
      `serp/refresh-query-results/`,
      query,
      authHeaders
    );
    return res.data;
  },
};
